<template>
  <div>
    <navbar
      ref="navbar"
      @getScreenWidths="getScreenWidths"
      @getScrollView="getScrollView"
    ></navbar>

    <div
      class="container"
      :style="screenWidths > 820 ? '' : 'padding-top:40px;'"
    >
      <div class="topShow">
        <img class="topShowImg" src="@/assets/image/aboutUs/top.png" alt="" />
      </div>

      <el-row style="margin: 0; display: flex; flex-wrap: wrap">
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 20, offset: 2 }"
          :xl="{ span: 16, offset: 4 }"
        >
          <div class="title" ref="aboutUs1">
            <div class="title-t">关于融智云</div>
            <div class="title-b"></div>
          </div>
          <!-- 员工风采 -->
          <div class="introduce">
            <div class="introduce-text">
              <p>
                福州市融智云科技有限公司是一家集设计、研发、运维、服务于一体的高科技IT企业，主要致力于电子商务。以软件外包和人员驻点为主要业务的IT信息化公司。
                公司成立于2019年，总部位于福州高新区创新园，目前拥有员工20余人，研发人员占比高达80%。
                <template v-if="textP">
                  福州市融智云科技有限公司富有朝气，团队90后占比
                  居高,研发部也不泛多名资深程序员、软件工程师组成的专业队伍。公司整体技术是以Java开发为主，项目多采后端分离模式。平台研发、互联网技术、网站设计、APP开发等,
                  截止目前，已获取计算机软件著作
                  权登记证书17个。公司项目服务对象以政府国有企业居多，包括福州著名的福诺、福富、新大陆、教育报、卫生报、江西农工党等。典型项目案例包括：福建省中国移动文档管理系统、江西农工党智慧农工管理平台、新大陆教育1+X证书推广平台等。要面向中小企业，助力于中小企业快速发展。我们始终坚持诚信和让利于客户，坚持用自己的服务打动客户。
                </template>
                <span v-if="textP" class="textMore" @click="getMoreText(false)"
                  >收起>></span
                >
                <span v-else class="textMore" @click="getMoreText(true)"
                  >更多>></span
                >
              </p>
            </div>
          </div>

          <div class="title" ref="aboutUs2">
            <div class="title-t">公司资质</div>
            <div class="title-b"></div>
          </div>
          <!-- 公司资质 -->
          <!-- module_left -->
          <div class="module_left">
            <el-row
              :gutter="32"
              style="margin: 0; display: flex; flex-wrap: wrap"
            >
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
              >
                <div
                  :class="effect2 ? 'module_left_i bounce-in-right' : 'disnone'"
                >
                  <img src="@/assets/image/aboutUs/zz.png" alt="" />
                </div>
              </el-col>
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
                style="height: 100%"
              >
                <div
                  :class="effect2 ? 'module_left_t bounce-in-right' : 'disnone'"
                >
                  <div class="module_left_t_title">软件著作权17项:</div>
                  <div class="module_left_t_text">
                    <p>水务信息化综合管控平台</p>
                    <p>致党E家管理平台</p>
                    <p>微信矩阵管理平台</p>
                    <p>外贸电商管理系统</p>
                    <p>CMS后台管理系统</p>
                    <p>智慧党建管理系统</p>
                    <p>......</p>
                    <p>正在申请软件企业认证、高新技术企业认证</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- 我们的客户 -->
          <div class="title" ref="aboutUs3">
            <div class="title-t">我们的客户</div>
            <div class="title-b"></div>
          </div>

          <div class="myClient">
            <div
              :class="effect3 ? 'myClient-item bounce-in-right' : 'disnone'"
              v-for="(item, index) in imglist"
              :key="index"
            >
              <el-image
                class="myClient-item-img"
                :src="item"
                fit="contain"
              ></el-image>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";

import fjjx from "@/assets/image/logo/fjjx.png";
import qmx from "@/assets/image/logo/qmx.png";
import xdl from "@/assets/image/logo/xdl.png";
import zdff from "@/assets/image/logo/zdff.png";
import fn from "@/assets/image/logo/fn.png";
import chw from "@/assets/image/logo/chw.png";
import hdss from "@/assets/image/logo/hdss.png";
import fjhxjyb from "@/assets/image/logo/fjhxjyb.png";
import jxngd from "@/assets/image/logo/jxngd.png";
import fjzl from "@/assets/image/logo/fjzl.png";

export default {
  components: { navbar },
  data() {
    return {
      imglist: [fjjx, qmx, xdl, zdff, fn, hdss, chw, fjhxjyb,jxngd,fjzl],
      screenWidths: 0,
      effect1: false,
      effect2: false,
      effect3: false,
      textP: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    getMoreText(e) {
      this.textP = e;
    },
    getScreenWidths(val) {
      this.screenWidths = val;
    },
    // 滚动条
    getScrollView(id) {
      if (id == 21) {
        this.$refs.aboutUs1.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 22) {
        this.$refs.aboutUs2.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 23) {
        this.$refs.aboutUs3.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },

    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(scrolled);
      if (scrolled > 120) {
        this.effect1 = true;
        this.effect2 = true;
        this.effect3 = true;
      }
      if (this.screenWidths < 820) {
        this.effect1 = true;
        this.effect2 = true;
        this.effect3 = true;
      }
    },
  },
};
</script>

<style  scoped>
.disnone {
  display: none;
}
.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.topShow {
  width: 100%;
  overflow: hidden;
}
.topShowImg {
  width: 100%;
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 00px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}

.textMore {
  color: #0063c9;
  cursor: pointer;
  font-size: 18px;
  /* padding-bottom: 2px; */
  border-bottom: 1px solid #0063c9;
}

.introduce {
  width: 100%;
  overflow: hidden;
}

.introduce-text {
  width: calc(100% - 32px);
  overflow: hidden;
  padding: 0 16px;
  padding-bottom: 32px;
}

.introduce-text p {
  font-size: 16px;
  color: #333;
  text-indent: 2em;
  line-height: 30px;
}

.introduce-imgbox {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.introduce-imgbox .imgitem {
  width: 300px;
  height: 185px;
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  padding: 8px;
  margin: 0 0 32px;
}
.introduce-imgbox .imgitem .img {
  width: 100%;
  height: 100%;
}

/* left */
.module_left {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  align-items: center;
}

.module_left_i {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_left_i img {
  width: 90%;
}

.module_left_t {
  width: 100%;
}

.module_left_t_title {
  width: 100%;
  line-height: 80px;
  font-size: 20px;
  color: #333;
  font-weight: bold;
}
.module_left_t_text {
  width: 100%;
  line-height: 40px;
  font-size: 16px;
  color: #666;
}
.module_left_t_text p {
  text-indent: 2em;
}

.myClient {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
}
.myClient-item {
  width: 180px;
  height: 80px;
  box-shadow: 0 0 10px rgba(95, 95, 95, 0.2);
  padding: 8px;
  margin: 0 16px 32px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0064c934;
}
.myClient-item-img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 800px) {
  .myClient {
    justify-content: space-around;
  }
  .myClient-item {
    width: 120px;
    height: 60px;
  }
  .module_left {
    margin-bottom: 0;
  }
}
</style>